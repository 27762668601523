html, body, #root {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: rgb(28, 47, 38);
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
